import React from 'react'
import '../../../screens/BootcampPage/BootcampPage.css'
import { trackMixpanel } from '../../../mixpanelWrapper';
import CountdownTimer from '../CountdownTimer';

function BootcampFormPayment({ targetDate }) {
    
    return (
        <div className='bootcamp-form-div-payment'>
            {/* Price */}
            <div className='bootcamp-form-div-payment-price'>
                <h1>$1000<span>/total</span></h1>
                <h2>OR</h2>
                <h1><span>3x</span> $360</h1>
                {/* <svg width="262" height="115" viewBox="0 0 262 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.7559 57.0307C50.9 60.3276 50.1645 63.4227 48.5493 66.3159C46.9914 69.1467 44.5733 71.5345 41.2951 73.4795C38.0743 75.362 34.1899 76.4927 29.6421 76.8716L29.9605 84.1546L24.2059 84.4062L23.8837 77.0332C17.5062 76.7714 12.2986 75.1072 8.2608 72.0406C4.22042 68.9141 1.95721 64.5387 1.47117 58.9146L17.8355 58.1993C18.3524 61.7802 20.1916 64.012 23.3532 64.8948L22.7322 50.6884C18.0043 49.6938 14.2068 48.6887 11.3397 47.6729C8.53256 46.6545 6.02497 44.9324 3.81694 42.5065C1.60891 40.0806 0.409262 36.6797 0.218008 32.3039C-0.0204047 26.8491 1.79642 22.3855 5.66849 18.9131C9.60051 15.4381 14.7343 13.352 21.0699 12.6547L20.7516 5.37161L26.5061 5.1201L26.8244 12.4031C33.1394 12.6076 38.1945 14.2184 41.9899 17.2356C45.7853 20.2527 48.0159 24.5694 48.6817 30.1857L32.2274 30.9049C31.7263 27.6837 30.1061 25.6525 27.3667 24.8113L27.9759 38.748C33.0114 39.9092 36.9014 40.9704 39.646 41.9315C42.3906 42.8925 44.8669 44.586 47.0749 47.0119C49.3403 49.3753 50.5673 52.7149 50.7559 57.0307ZM16.461 30.8733C16.5265 32.3719 17.0311 33.611 17.9748 34.5907C18.9784 35.5678 20.3664 36.408 22.1388 37.1113L21.5926 24.6132C19.9273 24.9863 18.6374 25.7033 17.7228 26.7643C16.8056 27.7653 16.3851 29.1349 16.461 30.8733ZM29.1195 64.913C30.9046 64.5347 32.2819 63.7538 33.2511 62.5704C34.2803 61.3843 34.7582 59.9521 34.6849 58.2737C34.6167 56.7152 34.0522 55.4786 32.9913 54.5641C31.9876 53.587 30.511 52.7807 28.5614 52.1452L29.1195 64.913Z" fill="white"/>
                    <path d="M57.604 23.6699L56.9792 9.37354L81.8854 8.28497L84.7542 73.9223L68.7495 74.6218L66.5055 23.2808L57.604 23.6699Z" fill="white"/>
                    <path d="M93.7983 39.7448C93.3477 29.4346 94.9708 21.2259 98.6677 15.1187C102.424 9.00881 108.739 5.76 117.61 5.37225C126.482 4.9845 133.025 7.67134 137.241 13.4328C141.516 19.1916 143.879 27.226 144.33 37.5362C144.786 47.9662 143.135 56.2362 139.379 62.3461C135.682 68.4533 129.398 71.7008 120.526 72.0886C111.655 72.4763 105.081 69.7908 100.806 64.032C96.5899 58.2706 94.2542 50.1748 93.7983 39.7448ZM129.225 38.1964C128.96 32.1422 128.097 27.5255 126.637 24.3462C125.174 21.107 122.375 19.5777 118.239 19.7585C114.103 19.9393 111.448 21.7069 110.273 25.0614C109.096 28.356 108.639 33.0303 108.904 39.0846C109.082 43.1607 109.47 46.537 110.067 49.2134C110.662 51.83 111.716 53.946 113.228 55.5615C114.797 57.1145 117.02 57.8281 119.897 57.7023C122.775 57.5765 124.897 56.673 126.265 54.9917C127.69 53.2478 128.585 51.0466 128.949 48.3882C129.311 45.6698 129.403 42.2725 129.225 38.1964Z" fill="white"/>
                    <path d="M152.453 37.1812C152.003 26.871 153.626 18.6623 157.323 12.555C161.079 6.44518 167.394 3.19637 176.265 2.80862C185.137 2.42087 191.68 5.10771 195.896 10.8691C200.171 16.6279 202.534 24.6624 202.985 34.9726C203.441 45.4026 201.79 53.6726 198.034 59.7824C194.337 65.8897 188.053 69.1372 179.181 69.5249C170.31 69.9127 163.736 67.2271 159.46 61.4684C155.245 55.7069 152.909 47.6112 152.453 37.1812ZM187.879 35.6328C187.615 29.5786 186.752 24.9618 185.292 21.7825C183.829 18.5433 181.03 17.0141 176.894 17.1949C172.758 17.3757 170.102 19.1433 168.928 22.4978C167.751 25.7923 167.294 30.4667 167.559 36.5209C167.737 40.597 168.125 43.9733 168.722 46.6498C169.317 49.2663 170.37 51.3823 171.882 52.9979C173.452 54.5508 175.675 55.2644 178.552 55.1387C181.43 55.0129 183.552 54.1094 184.92 52.428C186.345 50.6841 187.24 48.483 187.604 45.8245C187.966 43.1061 188.058 39.7089 187.879 35.6328Z" fill="white"/>
                    <path d="M211.108 34.6175C210.658 24.3074 212.281 16.0987 215.978 9.99142C219.734 3.88155 226.049 0.632736 234.92 0.244988C243.792 -0.14276 250.335 2.54408 254.551 8.3055C258.826 14.0643 261.189 22.0988 261.64 32.4089C262.096 42.839 260.445 51.1089 256.689 57.2188C252.992 63.3261 246.708 66.5735 237.836 66.9613C228.964 67.349 222.391 64.6635 218.115 58.9047C213.9 53.1433 211.564 45.0476 211.108 34.6175ZM246.534 33.0692C246.27 27.0149 245.407 22.3982 243.947 19.2189C242.484 15.9797 239.685 14.4505 235.549 14.6313C231.413 14.812 228.757 16.5797 227.583 19.9342C226.406 23.2287 225.949 27.9031 226.214 33.9573C226.392 38.0334 226.78 41.4097 227.377 44.0862C227.972 46.7027 229.025 48.8187 230.537 50.4342C232.107 51.9872 234.33 52.7008 237.207 52.575C240.084 52.4493 242.207 51.5457 243.575 49.8644C245 48.1205 245.895 45.9193 246.259 43.2609C246.621 40.5425 246.713 37.1453 246.534 33.0692Z" fill="white"/>
                    <path d="M188.945 79.7464L182.403 114.175L177.457 114.391L184 79.9626L188.945 79.7464Z" fill="#FFC300"/>
                    <path d="M201.483 103.372L201.673 107.717L199.065 107.831C197.207 107.913 195.739 107.526 194.66 106.673C193.581 105.799 192.997 104.333 192.907 102.275L192.616 95.6213L190.578 95.7104L190.392 91.4545L192.43 91.3654L192.252 87.2893L197.377 87.0653L197.555 91.1414L200.912 90.9947L201.098 95.2506L197.741 95.3973L198.034 102.111C198.056 102.61 198.192 102.965 198.441 103.174C198.691 103.383 199.095 103.476 199.654 103.451L201.483 103.372Z" fill="#FFC300"/>
                    <path d="M212.151 107.5C210.512 107.571 209.018 107.286 207.669 106.645C206.34 106.002 205.267 105.048 204.451 103.782C203.655 102.516 203.219 101.013 203.143 99.2751C203.068 97.5568 203.382 96.0316 204.084 94.6997C204.786 93.3477 205.781 92.2933 207.069 91.5363C208.357 90.7794 209.82 90.3651 211.459 90.2934C213.097 90.2218 214.591 90.5069 215.94 91.1486C217.29 91.7903 218.373 92.7539 219.19 94.0395C220.006 95.305 220.451 96.797 220.526 98.5154C220.601 100.234 220.278 101.769 219.557 103.122C218.854 104.454 217.849 105.499 216.541 106.257C215.252 107.014 213.789 107.428 212.151 107.5ZM211.957 103.064C212.936 103.021 213.749 102.625 214.397 101.876C215.065 101.126 215.37 100.082 215.311 98.7433C215.253 97.4046 214.868 96.3904 214.157 95.7008C213.467 95.0103 212.632 94.6864 211.653 94.7292C210.654 94.7729 209.84 95.1688 209.212 95.9169C208.583 96.6451 208.299 97.6885 208.358 99.0472C208.416 100.386 208.781 101.401 209.452 102.092C210.143 102.783 210.978 103.107 211.957 103.064Z" fill="#FFC300"/>
                    <path d="M232.742 102.005L232.932 106.351L230.324 106.465C228.466 106.546 226.998 106.16 225.92 105.306C224.84 104.433 224.256 102.967 224.166 100.909L223.875 94.2551L221.837 94.3441L221.651 90.0882L223.689 89.9991L223.511 85.923L228.636 85.699L228.814 89.7751L232.171 89.6284L232.357 93.8843L229 94.0311L229.294 100.745C229.315 101.244 229.451 101.599 229.7 101.808C229.95 102.017 230.354 102.11 230.914 102.085L232.742 102.005Z" fill="#FFC300"/>
                    <path d="M234.401 97.8789C234.326 96.1605 234.579 94.638 235.162 93.3113C235.765 91.9837 236.61 90.9458 237.699 90.1976C238.787 89.4493 240.02 89.0451 241.399 88.9848C242.578 88.9333 243.618 89.1281 244.518 89.5692C245.438 90.0095 246.155 90.6087 246.668 91.367L246.565 88.9993L251.69 88.7753L252.421 105.499L247.296 105.723L247.192 103.356C246.727 104.157 246.055 104.817 245.177 105.335C244.319 105.853 243.3 106.138 242.121 106.19C240.762 106.249 239.508 105.954 238.359 105.303C237.208 104.633 236.275 103.663 235.559 102.393C234.862 101.102 234.476 99.5973 234.401 97.8789ZM246.93 97.3613C246.874 96.0825 246.471 95.0892 245.719 94.3814C244.987 93.6726 244.112 93.3405 243.093 93.3851C242.074 93.4296 241.221 93.8372 240.534 94.608C239.866 95.3579 239.56 96.3722 239.616 97.651C239.672 98.9298 240.066 99.9335 240.798 100.662C241.55 101.37 242.435 101.702 243.454 101.657C244.473 101.613 245.317 101.205 245.984 100.436C246.671 99.6648 246.986 98.6401 246.93 97.3613Z" fill="#FFC300"/>
                    <path d="M260.284 82.9344L261.254 105.113L256.129 105.337L255.159 83.1584L260.284 82.9344Z" fill="#FFC300"/>
                </svg> */}
            </div>

            {/* Countdown Timer & Payment Button */}
            <div className='bootcamp-form-div-payment-main'>
                <h1>DORS CODING BOOTCAMP</h1>
                <CountdownTimer targetDate={targetDate} />
                <div className='bootcamp-form-div-payment-btns'>
                    <a 
                        href='https://dorscodingschool.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Coding-Bootcamp-1-installment-USD-Yearly&subscription_items[quantity][0]=1&layout=full_page' 
                        target='_blank' 
                        rel="noreferrer" 
                        onClick={trackMixpanel('ClickToPayBootcamp')} 
                    >
                        <button className='bootcamp-payment-button-book'>
                            ONE-TIME PAYMENT
                        </button>
                    </a>
                    <a 
                        href='https://dorscodingschool.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Coding-Bootcamp-1-installment-USD-Monthly&utm_source=cb-app-copy' 
                        target='_blank' 
                        rel="noreferrer" 
                        onClick={trackMixpanel('ClickToPayBootcamp')} 
                    >
                        <button className='bootcamp-payment-button-book'>
                            INSTALLMENTS
                        </button>
                    </a>
                </div>
            </div>

            {/* Spots Left */}
            <div className='bootcamp-form-div-payment-spots'>
                <div className='bootcamp-spots-card-row'>
                    <div className='bootcamp-spots-card'>0</div>
                    <div className='bootcamp-spots-card'>9</div>
                </div>
                <h1>SPOTS LEFT</h1>
            </div>
        </div>
    )
}

export default BootcampFormPayment